



























import { Component, Vue } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Player,
    HighlightText,
    Modal
  }
})
export default class ScreenOne extends Vue {
  private isText = false
  private isModal= false

  private videoIsEnd (): void {
    this.isText = true
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[4]
  }

  private openModal (): void {
    if (sessionStorage.getItem('timer/quidel/2') === 'isDone' || sessionStorage.getItem('timer/quidel/2') === 'isRun') {
      this.$emit('change-screen', 'Screen_2')
    } else {
      this.isModal = true
    }
  }
}
